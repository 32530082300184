.navbar {
  height: 50px;
  position: relative;
  width: 100%;
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(30, 30, 30); /* Rich Black background */
  align-content: center;
}

.navbar .nav-links {
  padding-top: 20px;
  padding-bottom: 0;
  padding-right: 50px;
  cursor: pointer;
}

.navbar .logo {
  font-size: 25px;
  font-weight: bold;
  left: 1rem;
  position: relative;
  color: #e0e0e0; /* Bright Gray for the logo text */
}

.navbar .nav-links ul {
  display: flex;
}

.navbar .nav-links ul li {
  margin: 0 25px;
}

.navbar a {
  color: #bb86fc; /* Soothing Purple for the links */
  transition: color 0.3s ease; /* Smooth transition for hover effects */
}

.navbar .menu-hamburger {
  display: none;
  color: #e0e0e0; /* Bright Gray for the hamburger icon */
  position: absolute;
  top: 5px;
  right: 40px;
  font-size: 40px;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .navbar .menu-hamburger {
    display: block;
  }

  .nav-links {
    top: 0;
    left: -100%;
    position: absolute;
    display: flex;
    justify-content: center;
    background-color: #121212; /* Consistent with the navbar background */
    width: 100%;
    padding-top: 3rem;
    transition: left 0.5s ease;
  }

  .nav-links.mobile-menu {
    left: 0;
  }

  .nav-links ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navbar .nav-links ul li {
    margin: 25px 0;
    font-size: 1.2em;
  }

  .menu-hamburger {
    display: block;
  }
}

.nav-link.active {
  color: #03dac6; /* Bright Teal for active link */
  font-weight: 600;
}

.nav-link:hover {
  color: #cab8ff; /* Lightened purple for hover */
}

.brand-logo {
  height: 30px;
  width: auto;
  right: 10px;
  position: relative;
}
